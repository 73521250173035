<template>
    <div style="margin:0 10px">
        <router-view></router-view> 
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <div style="width:160px;margin-right:10px;">
                        <el-input type="text" v-model="query.keyword" placeholder="关键字" clearable></el-input>  
                    </div>
                    <el-button type="primary" @click="init">查询</el-button>
                    <el-button type="primary" @click="$router.push({name:'user_add'})">+ 添加</el-button>    
                </div>

            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    :data="tableData"
                    style="width: 100%;"
                    v-loading="loading"
                    element-loading-background="rgba(255,255,255, 0.6)"
                    :header-cell-style="{background:'#f8f8f9',color:'#606266',borderBottom:'1px solid #e8eaec',padding:'8px 0',fontWeight:'700'}"
                >
                    <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="username" label="用户名" align="center"></el-table-column>
                    <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="enable" label="状态" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.enable" class="text-success">可用</span>
                            <span v-else class="text-danger">锁定</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="loginTimes" label="登录次数" align="center"></el-table-column>
                    <el-table-column prop="lastLoginTime" label="最后登录时间" align="center"></el-table-column>
                    <el-table-column prop="" label="操作" align="right" width="130">
                        <template slot-scope="scope">
                            <el-dropdown :hide-on-click="false">
                                <span class="el-dropdown-link">
                                    管理<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-if="scope.row.enable">
                                        <el-popconfirm
                                            icon="el-icon-info"
                                            icon-color="red"
                                            title="是否确定锁定？"
                                            placement="top"
                                            @confirm="handlelock(scope.row)"
                                        >
                                            <el-button slot="reference" type="text">锁定用户</el-button>
                                        </el-popconfirm>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-else>
                                        <el-popconfirm
                                            icon="el-icon-info"
                                            icon-color="red"
                                            title="是否确定解锁？"
                                            placement="top"
                                            @confirm="handleunlock(scope.row)"
                                        >
                                            <el-button slot="reference" type="text">解锁用户</el-button>
                                        </el-popconfirm>
                                    </el-dropdown-item>
                                    <!-- <el-dropdown-item command="reset">重置密码</el-dropdown-item> -->
                                    <el-dropdown-item>
                                        <el-popconfirm
                                            icon="el-icon-info"
                                            icon-color="red"
                                            title="是否确定重置密码？"
                                            placement="top"
                                            @confirm="handlereset(scope.row)"
                                        >
                                            <el-button slot="reference" type="text">重置密码</el-button>
                                        </el-popconfirm>
                                    </el-dropdown-item>
                                    <el-dropdown-item><el-button type="text" @click="$router.push({name:'user_edit',query:{id:scope.row.id}})">编辑</el-button></el-dropdown-item>
                                    <el-dropdown-item>
                                        <el-popconfirm
                                            icon="el-icon-info"
                                            icon-color="red"
                                            title="是否确定删除？"
                                            placement="top"
                                            @confirm="handledel(scope.row)"
                                        >
                                            <el-button slot="reference" type="text">删除</el-button>
                                        </el-popconfirm>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            
                            
                            
                            
                        </template>
                    </el-table-column>
                </el-table>

                <!-- page -->
                <div style="margin-top:20px;text-align:center">
                    <el-pagination
                        background
                        layout="total, prev, pager, next, sizes"
                        :total="total"
                        :page-sizes="pageSizes"
                        :page-size="query.limit"
                        @size-change="onSize"
                        @current-change="onCurrPage"
                        @prev-click="onCurrPage"
                        @next-click="onCurrPage"
                    >
                    </el-pagination>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {lists ,reset,lock,unlock,del} from '@/request/api/user'
export default {
    data(){
        return {
            tableData: [],
            query:{page:1,size:10,keyword:''},
            pageSizes:[20,30,50,100],
            total:0 , //列表总数
            loading:false,
            isEdit:false ,//编辑状态
        }
    },
    watch:{
        $route(val ,old){
            //返回刷新
            if(val.name=='user') {
                this.init()
            }
        }
    },
    created(){
        this.init()
    },

    methods:{
        async init(){
            this.tableData = [];
            this.loading=true
            let {code,data} =await lists(this.query)
            this.loading=false
            if(code!=0) return;
            this.tableData = data.list
            this.total = data.total
        },
        
        //重置
        async handlereset(row){
            let {code,msg} =await reset({userId:row.id})
            if(code!=0) return this.$message.error(msg)
            this.$message.success(msg)
            this.init()
        },
        async handlelock(row){
            let {code,msg} = await lock({userId:row.id})
            if(code!=0) return this.$message.error(msg)
            this.$message.success(msg)
            this.init()
        },
        async handleunlock(row){
            let {code,msg} = await unlock({userId:row.id})
            if(code!=0) return this.$message.error(msg)
            this.$message.success(msg)
            this.init()
        },
        async handledel(row) {
            let {code,msg} = await del({ids:[row.id]})
            if(code!=0) return this.$message.error(msg)
            this.$message.success(msg)
            this.init()
        },
        openEdit(row) {
            this.$router.push({name:'user_edit',query:{id:row.id}})
        },
        //每页条数
        onSize(val){
            this.query.size=val
            this.init()
        },
        onCurrPage(val){
            this.query.page = val
            this.init()
        }
    }
}
</script>


<style scoped lang="less">


</style>

